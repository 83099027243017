import {convertToPrice} from "../utiles/Ops";

export const CustomerLevel = ({user}) => {
  return (
    <div className="w-full h-[272px] gap-y-2">
        <div className="bg-[#221f3f] rounded h-[calc(50%-8px)] mb-2 justify-start flex flex-col items-center py-5">
            <div>{user?.rateName}</div>
            <hr className="bg-black w-[60%] mt-3 mb-2"/>
            <div className="text-[12px] py-1">امتیاز فعلی : {user?.rate}</div>
            <div className="text-[12px] py-1">{user?.nextRate} امتیاز مانده تا سطح بعدی</div>
        </div>
        <div className="bg-[#d9a406] rounded h-1/2 justify-center flex flex-col items-center">
        <div>مجموع خرید ۹۰ روز اخیر</div>
        <hr className="bg-black w-[60%] mt-3 mb-2"/>
        <div className="text-[16px]">{convertToPrice(user?.sumPriceOfInvoice)} ریال</div>
      </div>
    </div>
  );
};
