import {IconHome} from "../Icons/Home";
import {generateFullName} from "../../utiles/Ops";

export const HomeMenu = ({user}) => {
    return (
        <div className="flex justify-between items-start w-full bg-red-700 rounded p-3 mb-2">
            <div className="flex gap-x-2">
                <IconHome color="white"/>
                <div>{generateFullName(user)}</div>
            </div>
            <div className="flex gap-x-2">
                <span>{user?.rateName}</span>
            </div>
        </div>
    );
};
