import { IconUser, IconUserBold } from "../Icons/User";
import { HumberMenuIcon } from "../Icons/HumberMenu";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import useEventListener from "../../hooks/events";
import {convertEnToPe, generateFullName} from "../../utiles/Ops";
import { IconReqCard } from "../Icons/ReqCard";
import { IconFAQ } from "../Icons/Faq";
import { IconHelp } from "../Icons/Help";
import { IconInstagram, IconWhatsApp } from "../Icons/Social";
import { IconArrowLeft, IconArrowLeftUp } from "../Icons/Arrows";
import { useAuth } from "../../hooks/useAuth";

export default function Navigation() {
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const clickOUtSideRef = useRef(null);
  let location = useLocation();

  function clickOutside(e) {
    if (
      clickOUtSideRef.current &&
      !clickOUtSideRef.current.contains(e.target)
    ) {
      return setIsUserMenuOpen(false);
    }
  }

  useEventListener("mousedown", clickOutside, document);

  return (
    <nav className="bg-[#081325] sticky top-0 right-0 left-0 w-full max-w-screen-xl z-10">
      <div className="flex flex-wrap items-center justify-between mx-auto p-4 relative">
        <button
          ref={clickOUtSideRef}
          onClick={() => {
            setIsUserMenuOpen(!isUserMenuOpen);
          }}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg"
        >
          <HumberMenuIcon />
          {isUserMenuOpen ? (
            <RightMenu clickOUtSideRef={clickOUtSideRef} />
          ) : (
            ""
          )}
        </button>
        <div className="flex items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          {location?.pathname === "/" ? (
            <Link to="/profile">
              <IconUser className="fill-white" />
            </Link>
          ) : (
            ""
          )}
          {location?.pathname.includes("user-invoice") ? (
            <Link to="/invoices">
              <IconArrowLeft />
            </Link>
          ) : (
            ""
          )}

          {location?.pathname !== "/" &&
          !location?.pathname.includes("user-invoice") ? (
            <Link to="/">
              <IconArrowLeft />
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
    </nav>
  );
}

const RightMenu = () => {
  const { logout, user } = useAuth();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    setUserData(user);
  }, [user]);
  return (
    <div className="w-full max-w-[240px] absolute right-[1rem] top-[3.7rem]">
      <div className="rounded-xl pt-4 px-2 bg-gradient-to-b from-[#360a35] to-[#081325] min-h-[300px] shadow border border-[#1f1c1f]">
        <div className="m-auto flex w-full justify-between text-white flex-col gap-y-2">
          <div className="flex justify-center items-center w-[32px] h-[32px] bg-white rounded-full m-auto">
            <IconUserBold />
          </div>
          <div>{generateFullName(userData)}</div>
          <div>{convertEnToPe(userData?.party?.customerPhone)}</div>
        </div>
        <div className="flex w-full justify-between items-start text-white flex-col mt-5">
          <Link to="/shop" className="block w-full">
            <div className="flex justify-between py-3 px-1 text-right border-b-[1px] border-b-amber-50">
              <div className="flex gap-x-3">
                <IconReqCard />
                <div>درخواست کارت</div>
              </div>

              <IconArrowLeftUp />
            </div>
          </Link>
          <Link to="/faq" className="block w-full">
            <div className="flex justify-between py-3 px-1 text-right border-b-[1px] border-b-amber-50">
              <div className="flex gap-x-3">
                <IconFAQ />
                <div>انتقاد / پیشنهادات</div>
              </div>

              <IconArrowLeftUp />
            </div>
          </Link>
          <Link to="/help" className="block w-full">
            <div className="flex justify-between py-3 px-1 text-right border-b-[1px] border-b-amber-50">
              <div className="flex gap-x-3">
                <IconHelp />
                <div>راهنما</div>
              </div>

              <IconArrowLeftUp />
            </div>
          </Link>
          {user ? (
            <div className="flex w-full justify-between py-3 px-1 text-right border-b-[1px] border-b-amber-50">
              <div className="flex gap-x-3" onClick={logout}>
                <IconUser />
                <div>خروج</div>
              </div>

              <IconArrowLeftUp />
            </div>
          ) : (
            <Link to="/login" className="block w-full">
              <div className="flex justify-between py-3 px-1 text-right border-b-[1px] border-b-amber-50">
                <div className="flex gap-x-3">
                  <IconUser />
                  <div>ورود</div>
                </div>

                <IconArrowLeftUp />
              </div>
            </Link>
          )}
        </div>
        <div className="flex w-full justify-between py-5 px-10">
          <div className="p-1">
            <Link to="https://wa.me/0917000000" target="_blank">
              <IconWhatsApp color="#198754" />
            </Link>
          </div>
          <div className="p-1">
            <Link to="https://instagram.com/" target="_self">
              <IconInstagram color="#dc3545" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
