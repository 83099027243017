import Helmet from "react-helmet";
import {convertEnToPe, convertToPrice, ToShamsiEnToPe} from "../utiles/Ops";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {getUserData} from "../services/login";
import {Spinner} from "../compoenents/common/Spinner";
import {useNavigate} from "react-router-dom";

export default function Invoices() {
    const {setUser, token} = useAuth();
    const navigate = useNavigate();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserData().then((_userData) => {
            if (_userData?.status) {
                setUserData(_userData?.data);
                setUser(_userData?.data);
                setLoading(false);
            }
        });
    }, [token]);

    return (
        <div>
            <Helmet>
                <title>نمایش تمام فاکتور ها</title>
            </Helmet>
            {loading ? (
                <Spinner full/>
            ) : (
                <div className="rounded bg-[#221f3f] p-2 cursor-pointer">
                    <h1>آخرین فاکتور ها</h1>
                    <div className="mt-5">
                        {userData?.vwInvoices.length && userData?.vwInvoices?.map((item, key) => (
                            <div
                                key={key}
                                className="cursor-pointer"
                                onClick={() => navigate(`/user-invoice/${item.invoiceId}`)}
                            >
                                <div className="flex justify-between items-center gap-y-2 px-2 mb-2">
                                    <div>{item.name}</div>
                                    <div className="flex gap-x-2">
                                        <span>{convertToPrice(item?.netPrice)}</span>
                                        <span>ریال</span>
                                    </div>
                                </div>

                                <hr className="w-full mb-2"/>
                                <div className="flex justify-between items-center gap-y-2 px-2">
                  <span className="text-blue-500">
                    {convertEnToPe(item?.invoiceId)}
                  </span>
                                    <span className="text-green-800">
                    {ToShamsiEnToPe(item?.creationDate)}
                  </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
