import Helmet from "react-helmet";
import {convertEnToPe, convertToPrice, ToShamsiEnToPe} from "../utiles/Ops";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {Spinner} from "../compoenents/common/Spinner";

export default function Friends() {
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <Helmet>
                <title>نمایش تمام دوستان</title>
            </Helmet>
            {loading ? (
                <Spinner full/>
            ) : (
                <div className="rounded bg-[#221f3f] p-2 cursor-pointer">
                    <h1>نمایش تمام دوستان</h1>
                    <div className="mt-5">
                        {user?.partyRelateds.length && user?.partyRelateds?.map((item, key) => (
                            <div key={key}>
                                <div className="flex justify-between items-center gap-y-2 px-2 mb-2">
                                    <div>{item.name}</div>
                                    <div className="flex gap-x-2">
                                        <span>{convertEnToPe(item?.phone)}</span>
                                    </div>
                                </div>
                                <hr className="w-full mb-2"/>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
